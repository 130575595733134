<template>
  <div
    ref="nodeList"
    class="node-list"
  >
    <b-dropdown
      ref="nodeDropdown"
      :data-id="'dropdown-' + nodeId"
      aria-role="list"
      append-to-body
    >
      <template #trigger>
        <b-button
          class="p-0 m-0 list-toggle-btn"
          @click.stop="handleClick"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="mt-1 icon"
          />
        </b-button>
      </template>
      <b-dropdown-item
        v-if="allowedNodeListOptions.includes(nodeListDropdownTypes.BACKGROUND_ACTION)"
        :data-id="'dropdown-item-' + nodeId"
        aria-role="listitem"
        @click="addAction"
      >
        <b-icon
          class="mr-1"
          icon="view-dashboard-outline"
        />
        <span class="ml-2">Background action</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="allowedNodeListOptions.includes(nodeListDropdownTypes.INTEGRATION)"
        :data-id="'dropdown-item-' + nodeId"
        aria-role="listitem"
        @click="addIntegration"
      >
        <b-icon
          class="mr-1"
          icon="file-code-outline"
        />
        <span class="ml-2">Integration</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="allowedNodeListOptions.includes(nodeListDropdownTypes.SCREEN)"
        :data-id="'dropdown-item-' + nodeId"
        aria-role="listitem"
        @click="addScreen"
      >
        <b-icon
          class="mr-1"
          icon="monitor"
        />
        <span class="ml-2">Screen</span>
      </b-dropdown-item>
      <b-dropdown-item
        :data-id="'dropdown-item-' + nodeId"
        aria-role="listitem"
        @click="addConnection"
      >
        <b-icon
          class="mr-1"
          icon="connection"
        />
        <span class="ml-2">Connection</span>
      </b-dropdown-item>
      <b-dropdown-item
        :data-id="'dropdown-item-' + nodeId"
        aria-role="listitem"
        @click="addFinish"
      >
        <b-icon
          class="mr-1"
          icon="help-circle-outline"
          type="is-danger"
        />
        <span class="ml-2">Finish Application</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script >
// lib
import { ref, nextTick, onMounted } from '@vue/composition-api';
import { createPopper } from '@popperjs/core';
import { onClickOutside } from '@vueuse/core';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
// misc
import { delay, nextFrame } from '@/helpers/util';
import { generateNode } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';
import { nodeListDropdownTypes, useModuleGraph } from '@/modules/builder/components/module-graph/moduleGraph';

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- composable hooks --//
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const {
    allowedNodeListOptions
  } = useModuleGraph();

  /**
   * @param {string} nodeType 
   */
  const addNode = async nodeType => {
    try {
      const {
        appId
      } = route.params;
      const parentNode = moduleGraphStore.nodes.find(node => node.nodeId === props.nodeId);
      const nodeData = {
        type: nodeType,
        data: {
          name: ''
        }
      };
      const newNode = generateNode(moduleGraphStore.nodes, nodeData, parentNode);
      moduleGraphStore.nodes.push(newNode);
      await nextTick();
      updateNewNodeConnectionLabel(parentNode, newNode);
      await moduleGraphStore.addNodeInfo(appId, moduleStore.moduleId, newNode.nodeId, {});
    } catch (err) {
      console.error(err);
    }
  };
  const addScreen = () => {
    addNode('screen');
  };
  const addIntegration = () => {
    addNode('integration');
  };
  const addAction = () => {
    addNode('action');
  };
  const addFinish = () => {
    addNode('end');
  };
  const addConnection = () => {
    moduleGraphStore.connectionMode = true;
    moduleGraphStore.newConnection.sourceNode = moduleGraphStore.nodes.find(node => node.nodeId === props.nodeId);
    // removeOpacity();
  };
  const updateNewNodeConnectionLabel = (parentNode, newNode) => {
    const parentExistingConnections = parentNode.connections.filter(connection => connection.targetNodeId !== newNode.nodeId);
    const newConnection = parentNode.connections.find(connection => connection.targetNodeId === newNode.nodeId);
    if (parentExistingConnections.length) {
      const isDefaultConditionOnAllConnections = parentExistingConnections.some(connection => connection.condition.label === moduleGraphStore.defaultCondition.label);
      if (!isDefaultConditionOnAllConnections) {
        newConnection.condition.label = 'else';
      }
    }
  };

  //-- click outside logic --//
  /** @type {import('@vue/composition-api').Ref<HTMLElement | null>} */
  const nodeList = ref(null); // template ref
  onClickOutside(nodeList, () => {
    // removeOpacity();
  });

  //-- popperjs logic --//
  // @TO-DO's: create a custom dropdown component and include popper logic in that component (if required) - since popper doesn't integrate well with beufy's dropdown
  const nodeDropdown = ref(null);
  let popperInstance = null;
  onMounted(() => {
    const dropdownTrigger = document.querySelector(`[data-id="dropdown-${props.nodeId}"]`);
    const dropdownItem = document.querySelector(`[data-id="dropdown-item-${props.nodeId}"]`);
    popperInstance = createPopper(dropdownTrigger, dropdownItem.parentElement.parentElement, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom']
        }
      }
    });
  });
  const handleClick = async () => {
    nodeDropdown.value.isActive = !nodeDropdown.value.isActive; // manually toggle dropdown so that it is visible before running update on popper instance
    await nextFrame();
    await delay(300);
    await popperInstance.update(); // trigger update on popper instance (manual update is required here as buefy dropdowns are hidden by default, so popperjs can't detect it and trigger auto updates)
  };

  // //-- list focus logic --//
  // /**
  //  * @param {boolean} isDropdownActive
  //  */
  // const showAvailableNodes = (isDropdownActive) => {
  //     if (isDropdownActive) {
  //         removeOpacity();
  //     } else {
  //         const nodeElement = document.getElementById(props.id);
  //         if (nodeElement) {
  //             nodeElement.style.zIndex = '9999';
  //         }
  //         addOpacity();
  //     }
  // };
  // const addOpacity = (id = null) => {
  //     const flowRootElement = document.querySelector('.drawflow');
  //     const nodes = flowRootElement ? flowRootElement.getElementsByClassName('drawflow-node') : [];
  //     for (let i = 0; i < nodes.length; i++) {
  //         if (id) {
  //             if (id > filterId(nodes[i].id)) {
  //                 nodes[i].style.opacity = '0.1';
  //             }
  //         } else {
  //             nodes[i].style.opacity = '0.1';
  //         }
  //     }
  //     const connections = flowRootElement ? flowRootElement.getElementsByClassName('connection') : [];
  //     for (let i = 0; i < connections.length; i++) {
  //         if (id) {
  //             if (id > filterId(nodes[i].id)) {
  //                 connections[i].style.opacity = '0.1';
  //             }
  //         } else {
  //             connections[i].style.opacity = '0.1';
  //         }
  //     }
  //     const nodeElement = document.getElementById(props.id);
  //     if (nodeElement) {
  //         nodeElement.style.opacity = '1';
  //     }
  // };
  // const removeOpacity = () => {
  //     const flowRootElement = document.querySelector('.drawflow');
  //     const nodes = flowRootElement ? flowRootElement.getElementsByClassName('drawflow-node') : [];
  //     for (let i = 0; i < nodes.length; i++) {
  //         nodes[i].style.opacity = '1';
  //     }
  //     const connections = flowRootElement ? flowRootElement.getElementsByClassName('connection') : [];
  //     for (let i = 0; i < connections.length; i++) {
  //         connections[i].style.opacity = '1';
  //     }
  //     const nodeElement = document.getElementById(props.id);
  //     if (nodeElement) {
  //         nodeElement.style.zIndex = '1';
  //     }
  // };
  return {
    nodeListDropdownTypes,
    allowedNodeListOptions,
    addScreen,
    addIntegration,
    addAction,
    addFinish,
    addConnection,
    nodeList,
    nodeDropdown,
    handleClick
  };
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.node-list {
  .dropdown {
    .dropdown-content {
      padding: 15px 10px;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      border-radius: 8px;
    }
    .dropdown-trigger {
      background: transparent;
    }
  }
}

.list-toggle-btn {
  background: #FFFFFF;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  bottom: 0.8rem;

  .icon {
    color: $green;
  }
}

.slide-enter, .slide-leave-to {
  transform: scaleY(0);
}
</style>